import React, { useState, useEffect, useRef } from "react";
import _ from "underscore";
import { Table, Pagination } from "antd";
import { ROLE } from "../../../../constants";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import ExportDocBtn from "../../../../utils/exportDocBtn";
import { DatePicker } from "antd";
import moment from "moment";
import { OpenNotification } from "../../../shared/OpenNotification";

export const ItemLedgerDetails = (props) => {
  const [items, Setitems] = useState(
    props.content.itemData.ItemLedgerList.ledger
  );

  const [defaultName, setDefaultName] = useState(props.content.itemDetails);

  const opening_quantity =
    props.content.itemData.ItemLedgerList.quantity_in_stock;
  const [allItems, setAllItems] = useState(
    props.content.itemData?.AllItemList?.items
  );
  const updateQuantity = useRef(null);
  const [ItemRecord, setItemRecord] = useState({
    date: "",
    description: "",
    id: "",
    purchase: "",
    used: "",
    total_quantity: "",
    key_name: "",
  });
  const sessionValue = JSON.parse(localStorage.getItem("data"));

  const token = sessionValue?.loginData?.user?.token;
  useEffect(() => {
    Setitems(props.content.itemData.ItemLedgerList.ledger);
  }, [props.content.itemData.ItemLedgerList.ledger]);

  useEffect(() => {
    setAllItems(props.content.itemData?.AllItemList?.items);
    /* eslint-disable no-use-before-define */
  });
  function handleDateChange(date, dateString) {
    const currentDate = moment().format("YYYY-MM-DD");

    if (dateString <= currentDate) {
      setCreatedAt(dateString);

      if (defaultName.id !== null && dateString !== null) {
        props.content.handleLedgerItem(
          dateString,
          defaultName.id,
          activepage,
          recperpage
        );
      }
    } else {
      let notificationData = {
        type: "error",
        title: "Error",
        description: "Please select a past date",
        duration: 3,
      };
      OpenNotification(notificationData);
    }
  }

  const [recperpage, SetRecPerPage] = useState(5);
  const [activepage, SetActivePage] = useState(1);
  const [createdAt, setCreatedAt] = useState(null);
  const [itemId, setItemId] = useState(null);
  const sno = recperpage * (activepage - 1);
  let currentDate = new Date().toJSON().slice(0, 10);
  function handlePageChange(page) {
    SetActivePage(page);
    props.content.handlePagination(page, recperpage);
  }
  function handleLedgerItem(e) {
    const item_id = e.target.value;
    setItemId(item_id);
    if (item_id !== "" && createdAt != null) {
      props.content.handleLedgerItem(
        createdAt,
        item_id,
        activepage,
        recperpage
      );
    }
  }

  function applyFilter(e) {
    SetRecPerPage(e.target.value);
    props.content.handlePagination(1, e.target.value);
    SetActivePage(1);
  }

  function redirectOnDataPage(e, unique_id, key_name) {
    let path;
    if (key_name === "Purchase") {
      path = `/mrn/mrn-information/${unique_id}`;
      //  history.push(path);
    } else {
      path = `/store/item-information/${unique_id}`;
      //  history.push(path);
    }
    /* window.open(`${path}`,'_blank'); */
    window.open(path, "_blank");
  }

  const openModalBox = (record) => {
    setItemRecord({ ...record, id: record.id });
    updateQuantity.current.click();
  };
  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.sno}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "10%",
      fixed: "left",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.date), "MM/dd/yyyy")}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "12%",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.description}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "purchase",
      key: "purchase",
      width: "7%",
      render: (text, record, index) => (
        <div
          className="table-td-div"
          style={{ cursor: "pointer" }}
          onClick={() =>
            openModalBox({
              ...record,
              key_name: record.purchase > 0 ? "Purchase" : "Used",
            })
          }
        >
          <div className="table-td-div-inner">
            <span style={{ color: "blue" }}>
              {record.purchase > 0 ? record.purchase : record.used}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "purchase",
      key: "purchase",
      width: "7%",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.purchase > 0 ? "Purchase" : "Used"}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Total Quantity",
      dataIndex: "total_quantity",
      key: "total_quantity",
      width: "10%",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.total_quantity}</span>
          </div>
        </div>
      ),
    },
  ];

  const data = [];
  if (items) {
    items.forEach((item, index) => {
      data.push({
        sno: sno + index + 1,
        id: item.item_id ?? item.lead_id ?? item.mrn_id,
        date: item.date,
        description: item.description,
        purchase: item.purchase,
        used: item.used,
        total_quantity: item.total_quantity,
      });
    });
  }

  return (
    <section
      className="content"
      style={{ paddingTop: "4px", marginLeft: "0px" }}
    >
      <div className="row">
        <button
          type="button"
          ref={updateQuantity}
          style={{
            display: "none",
          }}
          className="btn btn-link btn-sm"
          data-toggle="modal"
          data-target={"#openAddStockDialog"}
        >
          <i className="fa fa-pencil text-primary" aria-hidden="true"></i>
        </button>

        <div
          className="modal fade"
          id={"openAddStockDialog"}
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">View Details</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                {ItemRecord.key_name === "Purchase" ? (
                  <p>
                    Purchase On{" "}
                    <b>{format(new Date(ItemRecord.date), "MM/dd/yyyy")}</b> for{" "}
                    <b
                      data-dismiss="modal"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={(event) =>
                        redirectOnDataPage(
                          event,
                          ItemRecord.id,
                          ItemRecord.key_name
                        )
                      }
                    >
                      {ItemRecord.description}
                    </b>{" "}
                  </p>
                ) : (
                  <p>
                    Used On <b>{ItemRecord.date}</b> for{" "}
                    <b
                      data-dismiss="modal"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={(event) =>
                        redirectOnDataPage(
                          event,
                          ItemRecord.id,
                          ItemRecord.key_name
                        )
                      }
                    >
                      {ItemRecord.description}
                    </b>{" "}
                  </p>
                )}
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 pinpin ">
          <div className="card lobicard" data-sortable="true">
            <div className="card-body">
              <div className=" minheight350">
                <div style={{ marginBottom: "30px" }}>
                  <div className="row  mb-3">
                    <div className="col-md-6" style={{ fontWeight: "bold" }}>
                      Opening Quantity : {opening_quantity}
                    </div>
                  </div>

                  <div className="row d-flex align-items-center">
                    <div className="col-md-5 form-inline">
                      <div className="row align-items-center">
                        <div className="col-md-4" style={{ marginTop: "1rem" }}>
                          <label style={{ fontSize: "0.7rem !important" }}>
                            Select Date :
                          </label>
                        </div>
                        <div className="input-group col-md-8">
                          <DatePicker
                            onChange={handleDateChange}
                            format="DD/MM/YYYY"
                            defaultValue={moment(currentDate, "YYYY-MM-DD")}
                          />
                          <input
                            type="date"
                            className="form-control p-0"
                            disabled
                            value={currentDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            value={defaultName?.item_name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 form-inline">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <label
                            className="control-label"
                            style={{ marginLeft: "10px" }}
                          >
                            Show :
                          </label>
                        </div>
                        <div className="col-md-4">
                          <select
                            className="form-control"
                            onChange={(event) => applyFilter(event)}
                            value={recperpage}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="col-md-4">per page</div>
                      </div>
                    </div>
                  </div>
                </div>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  scroll={{ x: 1500, y: 300 }}
                />
                <div className="mt-3">
                  <Pagination
                    total={props.content?.itemData?.ItemLedgerList?.total_items}
                    showSizeChanger={false}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      handlePageChange(page);
                    }}
                  />
                </div>

                {items &&
                items.length > 0 &&
                (props.content.content
                  ? props.content.content.userData.loginData.user.role.toLowerCase() ===
                    ROLE.Admin
                  : props.content.userData.loginData.user.role.toLowerCase() ===
                    ROLE.Admin) ? (
                  <div className="mt-3">
                    <ExportDocBtn
                      name="item_ledger"
                      item_id={itemId}
                      created_at={createdAt}
                      token={token}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
