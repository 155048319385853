import axios from "axios";
import { serverAddress } from "../../constants.js";
const sessionValue = JSON.parse(localStorage.getItem("data"));
const token = sessionValue?.loginData?.user?.token;

const utils = {
  addNewItem: (params) => {
    if (params.user_name !== null && params.password !== null) {
      let url = serverAddress + "/item/add";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
  getItem: (params) => {
    let url =
      serverAddress +
      `/item/all?page=${params.page}&limit=${params.limit}&search=${
        params.search ? params.search : ""
      }`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getAllItem: (params) => {
    let url = serverAddress + `/item/all-items`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getItemLedger: (params) => {
    let url =
      serverAddress +
      `/item/get-item-ledger/${params.id}?created_at=${params.created_at}&page=${params.page}&offset=${params.offset}&export_requested=${params.export_requested}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getItemDetails: (params) => {
    let url = serverAddress + `/item/item-details/${params.id}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getStock: () => {
    let url = serverAddress + "/item/all";
    return axios.get(url);
  },
  addStock: (params) => {
    let url = serverAddress + "/item/add-stock";
    return axios.put(url, params, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  assignStock: (params) => {
    if (params.user_name !== null && params.password !== null) {
      let url = serverAddress + "/item/assign";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
};

export default utils;
